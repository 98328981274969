import styled from 'styled-components';

import { COLOR, TYPOGRAPHY, GAP } from './theme';

const Button = styled.button`
  margin: ${({ margin }) => margin || '0'};
  padding: ${({ big }) => (big ? GAP.sm : GAP.xs)} ${GAP.sm};
  background-color: ${({ primary, green, transparent }) =>
    primary
      ? green
        ? COLOR.green
        : COLOR.black
      : transparent
      ? 'transparent'
      : COLOR.white};
  color: ${({ primary }) => (primary ? COLOR.white : COLOR.black)};
  box-shadow: ${({ shadow }) =>
    shadow ? `0px 1px 3px ${COLOR.darkGray}` : 'usnet'};
  /* border: 1px solid ${({ green }) => (green ? COLOR.green : COLOR.black)}; */
  border: none;
  border-radius: 4px;
  font-family: ${TYPOGRAPHY.font};
  font-size: ${TYPOGRAPHY.size.sm};
  transition: 0.3s all;
  cursor: pointer;

  &:hover,
  &:focus {
    border-color: ${COLOR.blue};
    background-color: ${COLOR.blue};
    color: ${COLOR.white};
  }

  &:disabled {
    border-color: ${COLOR.lightGray};
    background-color: ${COLOR.lightGray};
    color: ${COLOR.darkGray};
    cursor: not-allowed;
  }
`;

export default Button;
