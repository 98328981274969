import styled from 'styled-components';
import { COLOR, TYPOGRAPHY } from 'src/ui';
import { GlobalStyles } from 'src/components/GlobalStyles';

export const Placeholder = () => {
  return (
    <>
      <GlobalStyles />
      <Content>
        <img src='/social.png' alt='Spott.Link' draggable='false' />
      </Content>
    </>
  );
};

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${COLOR.blue};
  color: ${COLOR.white};
  font-size: ${TYPOGRAPHY.size.xxl};

  img {
    max-width: 40vw;
    border-radius: 30px;
    pointer-events: none;
  }
`;
