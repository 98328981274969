import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { COLOR } from './theme';
import { ScrollDepth } from '../common/utils';

const ScrollProgress = () => {
  const bar = useRef();

  useEffect(() => {
    if (bar) {
      const scrollDepth = new ScrollDepth({
        throttle: 0,
        scrollElement: document.getElementById('viewport'),
        onScroll: (value) => {
          bar.current.style.transform = `scaleX(${value})`;
        },
      });
      scrollDepth.start();

      return () => {
        scrollDepth.stop();
      };
    }
  }, [bar]);

  return (
    <Wrapper>
      <Bar ref={bar} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  border: 0;
  width: 100vw;
  height: 2px;
  background-color: ${COLOR.black};
  z-index: 99999;
`;

const Bar = styled.div`
  width: 100vw;
  height: 2px;
  background-color: ${COLOR.green};
  transform-origin: left;
  transform: scaleX(0);
`;

export default ScrollProgress;
