import { v4 as uuidv4 } from 'uuid';

export const IS_PROD =
  typeof window === 'undefined' || !window.location.href.includes('localhost:');

export const IS_MAINTENANCE_MODE = process.env.REACT_APP_MAINTENANCE === 'true';

export const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;
export const SITE_TITLE = process.env.REACT_APP_SITE_TITLE;
export const SITE_DESC = process.env.REACT_APP_SITE_DESC;
export const SITE_URL = process.env.REACT_APP_SITE_URL || process.env.SITE_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const AUTH_URL = process.env.REACT_APP_AUTH_URL;
export const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID;

const authParams = `client_id=${COGNITO_CLIENT_ID}&response_type=token&scope=email+openid+profile`;
export const SIGNUP_URL = `${AUTH_URL}/signup?${authParams}&redirect_uri=${SITE_URL}/signin/redirect`;
export const SIGNIN_URL = `${AUTH_URL}/login?${authParams}&redirect_uri=${SITE_URL}/signin/redirect`;
export const SIGNOUT_URL = `${AUTH_URL}/logout?${authParams}&redirect_uri=${SITE_URL}/signout/redirect`;
export const MAX_WIDTH = '1100px';
export const MAIN_ROUTE = '/dashboard';
export const TOKEN_STORAGE = process.env.REACT_APP_TOKEN_STORAGE;
export const BANNER_STORAGE = process.env.REACT_APP_BANNER_STORAGE;
export const SIGNIN_REDIRECT_STORAGE =
  process.env.REACT_APP_SIGNIN_REDIRECT_STORAGE;

export const PAYPAL = {
  currency: 'CAD',
  clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
  planId: {
    starter: process.env.REACT_APP_PAYPAL_PLAN_STARTER_ID,
    hosted: process.env.REACT_APP_PAYPAL_PLAN_HOSTED_ID,
    pro: process.env.REACT_APP_PAYPAL_PLAN_PRO_ID,
  },
};

export const VIEW_TYPE = {
  single: 'single',
  slides: 'slides',
  tiles: 'tiles',
};

export const VIEW_TYPES = [
  { value: VIEW_TYPE.tiles, label: 'Tiles' },
  { value: VIEW_TYPE.slides, label: 'Slides' },
  { value: VIEW_TYPE.single, label: 'Single' },
];

export const USER_PLAN = {
  starter: {
    id: 'starter',
    name: 'Starter',
    rate: 10,
    pages: 1,
    imagesPerPage: 20,
    hotspotsPerImage: 5,
    customLinks: 10,
  },
  hosted: {
    id: 'hosted',
    name: 'Growth',
    rate: 30,
    pages: 3,
    imagesPerPage: 30,
    hotspotsPerImage: 5,
    customLinks: 10,
  },
  pro: {
    id: 'pro',
    name: 'Scale',
    rate: 60,
    pages: 6,
    imagesPerPage: 30,
    hotspotsPerImage: 10,
    customLinks: 10,
    // embeds: 20,
    // imagesPerEmbed: 10,
    // embedRendersPerMonth: 10000000, // show first image only and without hotspots when quota reached
  },
  custom: {
    id: 'custom',
    name: 'Custom',
  },
};

export const SAMPLE_IMAGE = () => ({
  id: uuidv4(),
  src: `${SITE_URL}/ref/sample.png`,
  caption: 'This is a sample image.',
  maps: [
    {
      id: uuidv4(),
      top: '38.859649122807014%',
      left: '64.55026455026454%',
      width: '26.190476190476193%',
      height: '9.298245614035087%',
      link: 'https://link.to/product/1',
    },
    {
      id: uuidv4(),
      top: '73.42105263157895%',
      left: '4.232804232804233%',
      width: '24.6031746031746%',
      height: '19.298245614035086%',
      link: 'https://link.to/product/2',
    },
  ],
});

// TODO: add video and animated images (gif, apng, avif)
// https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types
export const ALLOWED_IMAGES = 'png|jpg|jpeg|webp|svg';
export const ALLOWED_IMAGE_CONTENT_TYPES = 'png|jpeg|webp|svg+xml';
