import styled from 'styled-components';

export const Hidden = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0;
  min-width: 0p;
  max-width: 0p;
  min-height: 0p;
  max-height: 0p;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
`;
