import styled from 'styled-components';

import { COLOR, MEDIA_QUERY, TYPOGRAPHY, GAP } from './theme';

const Subheading = styled.h2`
  margin: ${({ margin }) => margin || `0 auto ${GAP.sm} auto`};
  padding: 0;
  width: 100%;
  color: ${COLOR.blue};
  font-size: ${TYPOGRAPHY.size.md};

  ${MEDIA_QUERY.sm} {
    font-size: ${TYPOGRAPHY.size.lg};
  }
`;

export default Subheading;
