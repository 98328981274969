import styled from 'styled-components';
import { COLOR, GAP } from './theme';

const WarningBox = styled.div`
  padding: ${GAP.sm};
  color: ${COLOR.black};
  background-color: ${COLOR.yellow}90;
  border: 2px solid ${COLOR.yellow};
  border-radius: 4px;
`;

export default WarningBox;
