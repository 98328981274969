import { createGlobalStyle } from 'styled-components';
import { COLOR, TYPOGRAPHY } from 'src/ui';

export const globalStyles = `
  html, body {
    margin: 0;
    padding: 0;
    background-color: ${COLOR.black};
    font-family: ${TYPOGRAPHY.font};
    font-size: ${TYPOGRAPHY.size.sm};
    width: 100%;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
  }

  #app {
    width: 100%;
    min-height: 100vh;
  }

  * {
    box-sizing: border-box;
  }

  img {
    -webkit-user-drag: none;
    user-select: none;
  }
`;

export const GlobalStyles = createGlobalStyle`${globalStyles}`;
