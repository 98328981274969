import styled from 'styled-components';

import { GAP } from './theme';

const Box = styled.div`
  margin: ${({ margin }) => margin || '0'};
  padding: ${({ padding }) => padding || GAP.md};
  width: 100%;
  ${({ bg }) => (bg ? `background-color: ${bg};` : '')}
  border-radius: 4px;
`;

export default Box;
