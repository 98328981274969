import { forwardRef } from 'react';
import styled from 'styled-components';

import { COLOR, TYPOGRAPHY, GAP } from './theme';

const Input = forwardRef(
  ({ type, label, isLabelSpace, error, ...props }, ref) => {
    const isHidden = type === 'hidden';
    const WrapperComponent = isHidden ? Wrapper : WrapperWithForm;

    return (
      <WrapperComponent>
        {isLabelSpace && <Label>&nbsp;</Label>}
        {!isLabelSpace && !!label && !isHidden && <Label>{label}</Label>}
        <InputComponent ref={ref} type={type} invalid={!!error} {...props} />
        {!!error && <Error>{error.message}</Error>}
      </WrapperComponent>
    );
  },
);

const Wrapper = styled.label`
  display: block;
  margin: 0;
  padding: 0;
`;

const WrapperWithForm = styled(Wrapper)`
  form & {
    margin-bottom: ${GAP.md};
  }
`;

const InputComponent = styled.input`
  margin: 0;
  padding: ${GAP.xs};
  width: 100%;
  background-color: ${COLOR.white};
  color: ${COLOR.black};
  border: 1px solid ${COLOR.black};
  border-radius: 4px;
  font-family: ${TYPOGRAPHY.font};
  font-size: ${TYPOGRAPHY.size.sm};
  transition: 0.3s all;
  outline: none;

  &:focus&:not(:read-only) {
    border-color: ${COLOR.green};
  }

  :read-only {
    border-color: ${COLOR.gray};
    background-color: ${COLOR.lighterGray};
  }

  ${({ invalid }) =>
    invalid
      ? `
          border-color: ${COLOR.alert};

          &:focus {
            border-color: ${COLOR.alert};
          }
        `
      : ``}
`;

export const Label = styled.span`
  display: block;
  margin: 0 0 ${GAP.xxs} 0;
  color: ${COLOR.black};
  font-size: ${TYPOGRAPHY.size.sm};
`;

const Error = styled.div`
  margin: ${GAP.xxs} 0 0 0;
  font-size: ${TYPOGRAPHY.size.xs};
  color: ${COLOR.alert};
`;

export default Input;
