import styled from 'styled-components';

import { COLOR, GAP } from './theme';

const Loader = styled.div`
  display: ${({ inline }) => (inline ? 'inline-block' : 'block')};
  margin: ${({ inline }) => (inline ? 0 : `${GAP.md} auto`)};
  width: ${({ small, big }) => (small ? 30 : big ? 70 : 50)}px;
  height: ${({ small, big }) => (small ? 30 : big ? 70 : 50)}px;
  border-radius: 50%;
  border-top: ${({ small, big }) => (small ? 3 : big ? 5 : 4)}px solid
    rgba(31, 88, 132, 0.2);
  border-right: ${({ small, big }) => (small ? 3 : big ? 5 : 4)}px solid
    rgba(31, 88, 132, 0.2);
  border-bottom: ${({ small, big }) => (small ? 3 : big ? 5 : 4)}px solid
    rgba(31, 88, 132, 0.2);
  border-left: ${({ small, big }) => (small ? 3 : big ? 5 : 4)}px solid
    ${COLOR.secondary};
  transform: translateZ(0);
  animation: spin 1s infinite linear;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default Loader;
