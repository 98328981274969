import styled from 'styled-components';

import { COLOR, MEDIA_QUERY, TYPOGRAPHY, GAP } from './theme';

const Heading = styled.h1`
  margin: ${({ margin }) => margin || `0 auto ${GAP.md} auto`};
  padding: 0;
  width: 100%;
  color: ${({ secondary }) => (secondary ? COLOR.blue : COLOR.green)};
  font-size: 30px;

  ${MEDIA_QUERY.sm} {
    font-size: ${TYPOGRAPHY.size.xl};
  }
`;

export default Heading;
