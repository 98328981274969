import styled from 'styled-components';

const Flex = styled.div`
  margin: ${({ margin }) => margin || '0'};
  padding: 0;
  display: flex;
  ${({ justifyContent }) =>
    justifyContent ? `justify-content: ${justifyContent};` : ''}
  ${({ alignItems }) => (alignItems ? `align-items: ${alignItems};` : '')}
  ${({ dir }) => (dir ? `flex-direction: ${dir};` : '')}
  ${({ wrap }) => (wrap ? `flex-wrap: ${wrap};` : '')}
  width: ${({ width }) => width || 'auto'};
  border-radius: 4px;
`;

export default Flex;
