import { forwardRef } from 'react';
import styled from 'styled-components';
import { default as SelectComponent } from 'react-select';

import { COLOR, TYPOGRAPHY, GAP } from './theme';

const styles = {
  control: (provided, state) => ({
    ...provided,
    border: `1px solid ${state.isFocused ? COLOR.green : COLOR.black}`,
    ':hover': {
      borderColor: state.isFocused ? COLOR.green : COLOR.black,
    },
    borderRadius: '4px',
    boxShadow: 'none',
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: '1px',
    border: `1px solid ${COLOR.black}`,
    borderRadius: '4px',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    borderRadius: '4px',
  }),
};

const theme = (theme) => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    primary: COLOR.secondary,
    neutral0: COLOR.white,
  },
});

const Select = forwardRef(
  (
    { label, options, placeholder, isSearchable = false, error, ...props },
    ref,
  ) => (
    <Wrapper invalid={!!error}>
      {!!label && <Label>{label}</Label>}
      <SelectComponent
        ref={ref}
        options={options}
        styles={styles}
        theme={theme}
        placeholder={placeholder || ''}
        isSearchable={isSearchable}
        {...props}
      />
      {!!error && <Error>{error.message}</Error>}
    </Wrapper>
  ),
);

const Wrapper = styled.label`
  display: block;
  margin: 0;
  padding: 0;

  form & {
    margin-bottom: ${GAP.md};
  }

  ${({ invalid }) =>
    invalid
      ? `[class$="-control"] {
          border-color: ${COLOR.alert} !important;
        }`
      : ``}
`;

const Label = styled.span`
  display: block;
  margin: 0 0 ${GAP.xxs} 0;
  color: ${COLOR.black};
  font-size: ${TYPOGRAPHY.size.sm};
`;

const Error = styled.div`
  margin: ${GAP.xxs} 0 0 0;
  font-size: ${TYPOGRAPHY.size.xs};
  color: ${COLOR.alert};
`;

export default Select;
