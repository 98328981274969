import { createRoot } from 'react-dom/client';
import { Placeholder } from 'src/pages/Placeholder';
// import App from 'src/App';
// import { IS_PROD } from 'src/common/config';
// import { reportError } from 'src/common/utils';

const domNode = document.getElementById('app');
const root = createRoot(domNode);
root.render(<Placeholder />);

// if (IS_PROD && 'serviceWorker' in navigator) {
//   navigator.serviceWorker
//     .register('/sw.js')
//     .catch((error) => reportError({ source: 'sw', error }));
// }
