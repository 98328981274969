import styled from 'styled-components';
import { COLOR, GAP } from './theme';

const ErrorBox = styled.div`
  padding: ${GAP.sm};
  color: ${COLOR.black};
  background-color: ${COLOR.red}70;
  border: 2px solid ${COLOR.red};
  border-radius: 4px;
`;

export default ErrorBox;
