import styled from 'styled-components';

import { COLOR, TYPOGRAPHY, GAP } from './theme';

const Paragraph = styled.p`
  margin: ${({ margin }) => margin || `0 0 ${GAP.sm} 0`};
  padding: 0;
  width: 100%;
  color: ${COLOR.black};
  font-size: ${TYPOGRAPHY.size.sm};

  &:last-child {
    margin-bottom: 0;
  }
`;

export default Paragraph;
