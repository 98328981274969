import styled from 'styled-components';

import { COLOR, TYPOGRAPHY, GAP } from './theme';
import Checkmark from './Checkmark';

const Checkbox = ({ label, checked, ...props }) => (
  <Wrapper>
    <label>
      <CheckboxInput
        type='checkbox'
        {...props}
        {...(checked ? { defaultChecked: checked } : {})}
      />
      <Box>
        <Checkmark />
      </Box>
      <Label>{label}</Label>
    </label>
  </Wrapper>
);

const Wrapper = styled.div`
  position: relative;
  margin: 0 0 ${GAP.xs} 0;
  padding: 0;

  &:last-child {
    margin-bottom: 0;
  }

  label {
    cursor: pointer;
  }
`;

const CheckboxInput = styled.input`
  position: absolute;
  margin: 0;
  padding: 0;
  opacity: 0;
  outline: none;

  &:focus + div {
    border-color: ${COLOR.blue};
    background-color: ${COLOR.blue};
  }

  &:checked + div {
    border-color: ${COLOR.green};
    background-color: ${COLOR.green};

    svg {
      transform: translateY(1px) scale(0.7);
    }
  }

  + div svg path {
    fill: ${COLOR.black};
  }
`;

const Box = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin: 0 ${GAP.xs} 0 0;
  padding: 0;
  width: 20px;
  line-height: 20px;
  border: 1px solid ${COLOR.black};
  background-color: ${COLOR.white};
  border-radius: 2px;
  transition: 0.3s all;

  svg {
    transform: translateY(1px) scale(0);
    transition: 0.3s all;

    path {
      fill: ${COLOR.white};
    }
  }
`;

const Label = styled.span`
  display: inline-block;
  vertical-align: middle;
  line-height: 20px;
  color: ${COLOR.black};
  font-size: ${TYPOGRAPHY.size.sm};

  a {
    color: ${COLOR.black};
  }
`;

export default Checkbox;
