export const BREAK_POINT = {
  xs: 320,
  sm: 768,
  md: 900,
  lg: 1280,
  xl: 1920,
};

export const MEDIA_QUERY = {
  xs: `@media (max-width: ${BREAK_POINT.xs}px)`,
  sm: `@media (min-width: ${BREAK_POINT.sm}px)`,
  md: `@media (min-width: ${BREAK_POINT.md}px)`,
  lg: `@media (min-width: ${BREAK_POINT.lg}px)`,
  xl: `@media (min-width: ${BREAK_POINT.xl}px)`,
};

export const GAP = {
  xxs: '5px',
  xs: '10px',
  sm: '20px',
  md: '30px',
  lg: '50px',
  xl: '70px',
  xxl: '100px',
};

export const TYPOGRAPHY = {
  font: 'Quicksand, Helvetica, Arial',
  size: {
    xxs: '10px',
    xs: '12px',
    sm: '14px',
    md: '20px',
    lg: '22px',
    xl: '3vw',
    xxl: '5vw',
  },
};

export const COLOR_PALLETE = {
  black: '#0F0F0F',
  white: '#F7F9F9',
  blue: '#2f6690',
  green: '#AFC97E',
  gray: '#CCCCCC',
  lightGray: '#F1F1F1',
  lighterGray: '#EEEEEE',
  darkGray: '#666B6A',
  yellow: '#FEC601',
  red: '#DF2935',
};

export const COLOR = {
  ...COLOR_PALLETE,
  primary: COLOR_PALLETE.green,
  secondary: COLOR_PALLETE.blue,
  success: COLOR_PALLETE.green,
  warning: COLOR_PALLETE.yellow,
  alert: COLOR_PALLETE.red,
};
